.welcome-div {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 1.5em 15em;
}
.bio-div {
	overflow: hidden;
}
.p-intro {
	color: #1d1d1d;
	font-size: 1.4em;
	display: flex;
	justify-content: center;
	text-align: justify;
	padding-top: 0.3em;
}
.paragraph-div {
	font-family: 'Karla';
}
.bio-paragraphs {
	font-weight: 400;
	letter-spacing: 0.003em;
	line-height: 1.3em;
}
.portfolio-text-link {
	text-decoration: none;
	border-bottom: 0.125rem solid #297a4d;
	color: #1d1d1d;
	background-image: linear-gradient(
		180deg,
		white 0%,
		rgba(69, 192, 124, 0.5) 33%,
		rgba(41, 122, 77, 0.5) 66%,
		rgba(3, 160, 73, 0.5) 0%
	);
	background-repeat: repeat-x;
	background-position-y: bottom;
	background-size: 0.04em 0.04em;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.portfolio-text-link:hover {
	border-bottom: 0.125rem solid rgba(69, 192, 124, 0.7);
	background-size: 0.8em 0.8em;
}
.global-class-name {
	position: relative;
	top: 0.17em;
	left: 0.1em;
}
.circle-2 {
	margin-left: 0.1em;
	width: 1em;
	height: 1em;
	border: 0.1em solid #297a4d;
	border-radius: 1em;
	display: inline-block;
}
.hell {
	font-size: 3em;
	font-weight: 300;
	letter-spacing: 0.02em;
}
.circle-span {
	position: relative;
	top: 0.035em;
}
.skills-div {
	display: flex;
	flex-direction: column;
	margin-bottom: 3em;
}
.skills-list {
	font-size: 0.9em;
	font-family: 'Karla';
}
.skills-list > li {
	padding: 0.1em;
}
.skills-list > li:last-child {
	margin-right: 0;
}
.icon-span {
	color: #297a4d;
	font-size: 0.9em;
	padding: 1em 1em 0 0;
}
.skill-span {
	position: relative;
	bottom: 3px;
}

/* for larger screens */
@media only screen and (min-width: 1250px) {
	.welcome-div {
		padding: 0 24em;
	}
}

/* tablets */

@media screen and (max-width: 768px) and (orientation: portrait) {
	.welcome-div {
		padding: 0;
		margin-top: 0.5em;
	}
	.hell {
		font-size: 2em;
	}
	.p-intro {
		margin: 1em 3.5em;
	}
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	.welcome-div {
		padding: 0;
	}
	.p-intro {
		margin: 0 10em;
	}
}

/* mobile */

@media screen and (max-width: 414px) and (orientation: portrait) {
	.welcome-div {
		padding: 0;
		margin-top: 2em;
	}
	.p-intro {
		font-size: 1.4em;
		margin: 0.5em 0.3em;
		padding: 1em;
		text-align: left;
	}
	.skills-div {
		display: inline;
		margin-top: 2.8em;
	}
	.skills-list {
		font-size: 1em;
	}
}

@media screen and (max-width: 823px) and (orientation: landscape) {
	.welcome-div {
		padding: 0;
	}
	.p-intro {
		margin: 0 6em;
		font-size: 1.2em;
	}
	.skills-div > ul {
		font-size: 1.1em;
	}
	.paragraph-bio:first-child {
		text-align: center;
	}
}
